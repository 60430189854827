// 현재 파일은 기본 제공 디자인입니다. 수정하지 말아주세요.
import styled from "styled-components";
import CheckInContainer from "./containers/CheckInContainer";
import CheckInPaymentContainer from "./containers/CheckInPaymentContainer";
import CheckInPaymentResponseContainer from "./containers/CheckInPaymentResponseContainer";
import CheckOutPaymentContainer from "./containers/CheckOutPaymentContainer";
import CheckOutPaymentResponseContainer from "./containers/CheckOutPaymentResponseContainer";
import CheckOutPaymentSuccessContainer from "./containers/CheckOutPaymentSuccessContainer";
import FrontInfoContainer from "./containers/FrontInfoContainer";

//결제 결과(체크아웃)
const CheckOutPaymentSuccess = styled(CheckOutPaymentSuccessContainer)`
  .check-out-success-info-container {
    margin: 50px 0 0;
    text-align: center;
  }
  .check-out-success-info-container img.check-out-success {
    width: 200px;
  }
  .check-out-success-info-container .check-out-success-title {
    display: block;
    margin: 30px 0 0;
    font-size: 14px;
    color: #a2a2a2;
  }
  .check-out-success-info-container .check-out-success-description {
    display: block;
    margin: 15px 0;
    font-size: 16px;
    white-space: pre-line;
    word-break: keep-all;
  }
  .check-out-success-info-container .check-out-success-sub-description {
    display: block;
    margin: 15px 0;
    font-size: 16px;
    white-space: pre-line;
    word-break: keep-all;
    color: #666;
  }
  @media (max-width: 370px) {
    .check-out-success-info-container .check-out-success-title {
      font-size: 12px;
    }
    .check-out-success-info-container .check-out-success-description {
      font-size: 14px;
    }
    .check-out-success-info-container .check-out-success-sub-description {
      font-size: 14px;
    }
  }
`;

//결제 화면(체크아웃)
const CheckOutPaymentResponse = styled(CheckOutPaymentResponseContainer)`
  .payment-response-icon {
    margin: 40px 0 0;
    text-align: center;
    font-size: 100px;
    color: #8fb0cf;
  }
  .payment-response-title {
    display: block;
    margin: 14px 0;
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.4px;
    text-align: center;
    color: #000;
  }
  .payment-response-description {
    display: block;
    margin: 0 0 20px;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.4px;
    text-align: center;
    word-break: keep-all;
    color: #666;
  }
`;

//체크아웃
const CheckOutPayment = styled(CheckOutPaymentContainer)`
  .check-in-payment-title {
    display: block;
    margin: 30px 0 30px;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.8px;
    color: #000;
  }
  .check-in-payment-description {
    display: block;
    margin: 10px 0 15px;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.4px;
    color: #666;
  }
  .product-name-container {
    position: relative;
    margin: 0 0 8px;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.4px;
    color: #666;
  }
  .product-name-container .product-name-value {
    position: absolute;
    right: 0;
    font-weight: 500;
    color: #000;
  }
  .total-amount-container {
    height: 35px;
    position: relative;
    margin: 0 0 30px;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.4px;
    line-height: 35px;
    color: #666;
  }
  .total-amount-container .total-amount-value {
    position: absolute;
    right: 0;
    font-size: 24px;
    font-weight: 500;
    color: #b2202d;
  }
  button.check-in-payment-button {
    width: 100%;
    height: 47.25px;
    font-size: 16px;
    background-color: #333;
    border: 1px solid #333;
    color: #fff;
  }
`;

//결제 결과
const CheckInPaymentResponse = styled(CheckInPaymentResponseContainer)`
  .payment-response-icon {
    margin: 40px 0 0;
    text-align: center;
    font-size: 100px;
    color: #8fb0cf;
  }
  .payment-response-title {
    display: block;
    margin: 14px 0;
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.4px;
    text-align: center;
    color: #000;
  }
  .payment-response-description {
    display: block;
    margin: 0 0 20px;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.4px;
    text-align: center;
    word-break: keep-all;
    color: #666;
  }
`;

//결제 화면
const CheckInPayment = styled(CheckInPaymentContainer)`
  .check-in-payment-title {
    display: block;
    margin: 30px 0 30px;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.8px;
    color: #000;
  }
  .check-in-payment-description {
    display: block;
    margin: 10px 0 15px;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.4px;
    color: #666;
  }
  .product-name-container {
    position: relative;
    margin: 0 0 8px;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.4px;
    color: #666;
  }
  .product-name-container .product-name-value {
    position: absolute;
    right: 0;
    font-weight: 500;
    color: #000;
  }
  .total-amount-container {
    height: 35px;
    position: relative;
    margin: 0 0 30px;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.4px;
    line-height: 35px;
    color: #666;
  }
  .total-amount-container .total-amount-value {
    position: absolute;
    right: 0;
    font-size: 24px;
    font-weight: 500;
    color: #b2202d;
  }
  button.check-in-payment-button {
    width: 100%;
    height: 47.25px;
    font-size: 16px;
    background-color: #333;
    border: 1px solid #333;
    color: #fff;
  }
  .payment-error-message {
    width: 100%;
    font-size: 14px;
    text-align: center;
    color: #ff4129;

    .error-message {
      margin-top: 5px;
      font-size: 0.875em;
    }
  }
`;

//체크인 화면
const CheckIn = styled(CheckInContainer)`
  .verification-title {
    display: block;
    margin: 30px 0 10px;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.8px;
    color: #000;
  }
  .verification-description {
    display: block;
    margin: 10px 0 30px;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.4px;
    color: #666;
  }
  .verification-item-label {
    display: block;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.4px;
    color: #999;
  }
  input.check-in-input {
    width: 100%;
    height: 40px;
    border: unset;
    border-bottom: 1px solid #ebebeb;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.4px;
    color: #000;
  }
  input:focus-visible {
    outline: unset;
    border-bottom: 1px solid #000;
  }
  input::placeholder {
    font-weight: 300;
    color: #d6d6d6;
  }
  button.verification-code-send-button {
    position: absolute;
    top: 4px;
    right: 0;
    width: 86px;
    height: 30px;
    padding: 0;
    border-radius: 15px;
    border: solid 1px #000;
    background-color: #000;
    font-size: 11px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.2px;
    color: #fff;
  }
  button.verification-code-send-button:disabled {
    background-color: #fff;
    border: solid 1px #ebebeb;
    color: #b6b6b6;
  }
  .verification-code-resend-button {
    position: absolute;
    top: 2px;
    right: 0;
    width: 56px;
    height: 36px;
  }
  .verification-code-resend-button .verification-code-resend-button-text {
    display: block;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 36px;
    letter-spacing: -0.4px;
    text-align: center;
    color: #ff4129;
  }
  img.notice-icon {
    width: 14px;
    height: 14px;
    object-fit: contain;
  }
  .kakao-notice {
    height: 48px;
    margin: -1px 0 0;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.4px;
    color: #666;
  }
  .kakao-notice .kakao-notice-link {
    margin: 0 3px;
    color: #000000;
    font-weight: 500;
    text-decoration: underline;
  }
  img.kakao-login-button {
    width: 100%;
  }
`;

const FrontInfo = styled(FrontInfoContainer)`
  .front-info-container {
    margin: 50px 0 0;
    text-align: center;
  }
  .front-info-container img.front-success {
    width: 70%;
    max-width: 250px;
  }
  .front-info-container .front-success-title {
    display: block;
    margin: 30px 0 0;
    font-size: 14px;
    color: #a2a2a2;
  }
  .front-info-container .front-success-description {
    display: block;
    margin: 15px 0;
    font-size: 16px;
    white-space: pre-line;
    word-break: keep-all;
  }
  .front-info-container .front-success-sub-description {
    display: block;
    margin: 15px 0;
    font-size: 16px;
    white-space: pre-line;
    word-break: keep-all;
    color: #666;
  }
  @media (max-width: 370px) {
    .front-info-container .front-success-title {
      font-size: 12px;
    }
    .front-info-container .front-success-description {
      font-size: 14px;
    }
    .front-info-container .front-success-sub-description {
      font-size: 14px;
    }
  }
`;

const Styled = {
  CheckIn,
  CheckInPayment,
  CheckInPaymentResponse,
  CheckOutPayment,
  CheckOutPaymentResponse,
  CheckOutPaymentSuccess,
  FrontInfo,
};

export default Styled;
