import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as api from "../api/sanha";
import * as serverApi from "../api/server";
import { bookingListAction } from "../stores/actions";
import ModalError from "../components/ModalError";
import Spinner from "../components/Spinner";

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode };

const RoomSelectContainer = (props: Props) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { bookingItem, userInfo, queryStringParams } = useSelector(
    (state: any) => state.bookingList
  );

  const [isLoading, setIsLoading] = useState(false);
  const [roomList, setRoomList] = useState<any>([]); //선택 가능한 객실 목록
  const [selectedRoom, setSelectedRoom] = useState("");
  const [isOpenModalError, setIsOpenModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [modalErrorSubMessage, setModalErrorSubMessage] = useState("");

  const openModalError = () => {
    setIsOpenModalError(true);
  };

  const closeModalError = () => {
    setIsOpenModalError(false);
  };

  const listAvailableRoom = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await api.listAvailableRoom({
        rsvnNo: bookingItem.rsvnNo,
        rsvnSeqNo: "1",
      });

      if (response.code !== "0000" && !response.data) {
        setModalErrorMessage("사용 가능 객실 조회에 실패 하였습니다.");
        throw new Error(`${response.code}, ${response.message}`);
      }
      if (response.data) {
        const filteredRoomList = response.data
          .filter((roomInfo) => roomInfo.roomStatus === "VI")
          .map((room) => room.roomNo);

        setRoomList(filteredRoomList);
      }
    } catch (error: any) {
      setModalErrorMessage(error.response?.data?.message);
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeRoom = (roomNo: string) => {
    if (selectedRoom === roomNo) return;
    setSelectedRoom(roomNo);
  };

  const handleDeposit = async (depositType: string) => {
    try {
      if (depositType === "DEPOSIT") {
        const response = await api.checkInDeposit({
          rsvnNo: bookingItem.rsvnNo,
          rsvnSeqNo: "1",
          trnsNo: queryStringParams.shopOrderNo, //거래 승인 번호
          intApprTypeCode: "P", //인터넷 결제 유형 코드
          intPayStatusCode: 11, //인터넷 결제 상태 코드
          payFnshDate: moment().format("YYYYMMDD"), //인터넷 지불 일자
          successYn: queryStringParams.successYn,
          trnsDate: moment().format("YYYYMMDD"), //거래일자
          trnsHHMMSS: moment().format("HHmmss"),
          trnsAmt: queryStringParams.trnsAmt,
          cardMonthCnt: queryStringParams.cardMonthCnt || "", //할부 개월 수
          cardApprNo: queryStringParams.trnsNo || "", //카드 승인 번호 (간편결제 시 null이라서 pgCno 값 적용)
          cardName: queryStringParams.cardName || "", //카드명
          cardIssueCode: queryStringParams.cardIssueCode || "", //카드 발급사 코드
          cardPurcCode: queryStringParams.cardPurcCode || "", //카드 매입사 코드
          trnsBankCode: "", //거래 은행 코드
          acctNo: "", //계좌번호
          depositor: queryStringParams.depositor || "", //예금주명
          cashRcptTypeCode: 0, //현금 영수증 발급 구분 코드
          cashRcptApptNo: "", //현금 영수증 승인 번호
          cashRcptCxlApptNo: "", //현금 영수증 취소 승인 번호
          responseCode: queryStringParams.responseCode || "",
          message1: queryStringParams.responseMessage || "",
          message2: "",
          dpsitPayDivCode: "01",
          userId: "KEYLESS",
          userIp: "127.0.0.0",
          payMgtNo: "",
          dpsitPayNo: "",
          outYN: "",
          outMsg: "",
        });
        if (response.code !== "0000") {
          setModalErrorMessage(
            "결제는 성공하였으나, 이후 처리 과정에서 문제가 생겼습니다."
          );
          throw new Error(`${response.code}, ${response.message}`);
        }
      } else if (depositType === "PAYMENT") {
        const response = await api.checkInAdvancePayment({
          folioNo: userInfo.foliNo,
          trnsNo: queryStringParams.shopOrderNo, //거래 승인 번호
          intApprTypeCode: "P", //인터넷 결제 유형 코드
          intPayStatusCode: 11, //인터넷 결제 상태 코드
          payFnshDate: moment().format("YYYYMMDD"), //인터넷 지불 일자
          successYn: queryStringParams.successYn,
          trnsDate: moment().format("YYYYMMDD"), //거래일자
          trnsHHMMSS: moment().format("HHmmss"),
          trnsAmt: queryStringParams.trnsAmt,
          cardMonthCnt: queryStringParams.cardMonthCnt || "", //할부 개월 수
          cardApprNo: queryStringParams.trnsNo || "", //카드 승인 번호 (간편결제 시 null이라서 pgCno 값 적용)
          cardName: queryStringParams.cardName || "", //카드명
          cardIssueCode: queryStringParams.cardIssueCode || "", //카드 발급사 코드
          cardPurcCode: queryStringParams.cardPurcCode || "", //카드 매입사 코드
          trnsBankCode: "", //거래 은행 코드
          acctNo: "", //계좌번호
          depositor: queryStringParams.depositor || "", //예금주명
          cashRcptTypeCode: 0, //현금 영수증 발급 구분 코드
          cashRcptApptNo: "", //현금 영수증 승인 번호
          cashRcptCxlApptNo: "", //현금 영수증 취소 승인 번호
          responseCode: queryStringParams.responseCode || "",
          message1: queryStringParams.responseMessage || "",
          message2: "",
          userId: "KEYLESS",
          userIp: "127.0.0.0",
          payMgtNo: "",
          dpsitPayNo: "",
          outYN: "",
          outMsg: "",
        });
        if (response.code !== "0000") {
          setModalErrorMessage(
            "결제는 성공하였으나, 이후 처리 과정에서 문제가 생겼습니다."
          );
          throw new Error(`${response.code}, ${response.message}`);
        }
      }
    } catch (error: any) {
      setModalErrorMessage(error.response?.data?.message);
      setModalErrorSubMessage(error.message);
      openModalError();
    }
  };

  const checkIn = async () => {
    try {
      setIsLoading(true);

      if (!bookingItem.rsvnNo) throw new Error("체크인을 할 수 없습니다.");
      const response = await serverApi.checkIn({
        rsvnNo: bookingItem.rsvnNo,
        rsvnSeqNo: "1",
        roomNo: bookingItem.roomNo || selectedRoom,
      });
      if (response.code !== "0000") {
        setModalErrorMessage("체크인에 실패 하였습니다.");
        throw new Error(`${response.code}, ${response.message}`);
      }

      const checkInPaymentResponse = await api.config();
      const { depositUseYn, pgPaymentYn, depositType } =
        checkInPaymentResponse.data;

      if (depositUseYn === "Y" || pgPaymentYn === "Y") {
        handleDeposit(depositType);
      }
      // 결제 내역 초기화
      dispatch(bookingListAction.setBookingQueryStringParams(""));
      navigation(`/?rsvnNo=${bookingItem.rsvnNo}`, { replace: true });
    } catch (error: any) {
      setModalErrorMessage(error.response?.data?.message);
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckBooking = async () => {
    try {
      const response = await serverApi.listBooking({
        rsvnNo: bookingItem.rsvnNo,
        rsvnSeqNo: "1",
      });

      if (response.code === "0000" && response.data) {
        if (response.data.roomNo) {
          checkIn();
          return;
        }
        listAvailableRoom();
      }
    } catch (error: any) {
      alert(error.message);
    }
  };

  useEffect(() => {
    handleCheckBooking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <Spinner isLoading={isLoading} />;

  return (
    <div {...props}>
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
    </div>
  );
};

export default RoomSelectContainer;
